import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Layout from 'components/Layout'
import GridIcon from 'components/icons/GridIcon.js'

const IndexPage = ({ data }) => {
  const categories = data.allCategoriesYaml.edges.map((edge) => edge.node)

  const imageLoaded = () => {
    // sometimes the alice carousel show the images in the wrong sizes.
    // this hack prevents that by forcing the alice carousel to recalculate sizes after a image has loaded
    window.dispatchEvent(new Event('resize'))
  }

  return (
    <Layout pageTitle='Hem' headerText='NATURSKYLT'>
      <oma-grid-row>
        <section className='article__content page__column'>
          <p>
            Skyltar i naturen är ett fantastiskt sätt att förmedla kunskap som
            ger mervärde och sammanhang till en natur- eller kulturupplevelse.
            Jag skapar informationsprodukter där text, bild och formgivning
            gemensamt bidrar till en ökad förståelse för ett besöksmål. Min
            ambition är att skapa innehållsrik information på ett konstnärligt
            tilltalande sätt.
          </p>
          <p>
            Mina uppdragsgivare är alla som önskar rikta information om natur
            och kultur till allmänheten, exempelvis myndigheter och föreningar.
            Jag illustrerar, redigerar och formger befintliga texter, eller
            skriver själv i dialog med uppdragsgivare och expertis.
          </p>
        </section>
        <section className='article__content page__column'>
          <div className='slider-container'>
            {categories.map((category) => (
              <React.Fragment key={category.page}>
                <Link to={`galleri/${category.page}`} className='gallery-link'>
                  <h2 className='gallery-link__text'>{category.title}</h2>{' '}
                  <GridIcon className='gallery-link__icon' />
                </Link>
                <div className='image-slider'>
                  <AliceCarousel
                    renderPrevButton={() => (
                      <div className='custom-arrow custom-arrow--left' />
                    )}
                    renderNextButton={() => (
                      <div className='custom-arrow custom-arrow--right' />
                    )}
                    animationDuration={300}
                    disableDotsControls={true}
                    infinite={true}
                    items={category.images.map((image) => (
                      <div className='image-slider__image-container'>
                        <Img
                          onLoad={imageLoaded}
                          fluid={image.childImageSharp.fluid}
                        />
                      </div>
                    ))}
                  ></AliceCarousel>
                </div>
              </React.Fragment>
            ))}
          </div>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCategoriesYaml {
      edges {
        node {
          page
          title
          images {
            ...categoryImage
          }
        }
      }
    }
  }
`

export default IndexPage
